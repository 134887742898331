<template>
  <div class="line_wrap">
    <div class="parent">
      <el-col :span="12">
        <div class="subject"
             :style="{'margin-left':20 * (row.level-1)+'rem'}">
          <div v-if="row.child && row.child.length">
            <img src="@/assets/dayi/+.png"
                 v-if="row.show"
                 @click="cut(false)"
                 alt="">
            <img src="@/assets/dayi/-.png"
                 v-else
                 @click="cut(true)"
                 alt="">
          </div>
          {{row.chapter_desc}}
        </div>
      </el-col>
      <el-col :span="6">
        <div class="pl">
          星级:
          <img src="@/assets/icons/icon-星星.png"
               v-for="(item,index) in row.chapter_star"
               :key="index" />
        </div>
      </el-col>
      <el-col :span="6"
              style="text-align:right">
        <div class="btn"
             @click="doPaper(row)"
             v-if="row.level <= 2">
          开始训练
        </div>

        <div class="btn_img"
             v-else>
          <img src="@/assets/icons/训练.png"
               alt=""
               @click="doPaper(row)">
        </div>
      </el-col>
    </div>
    <div v-if="row.child && row.child.length && !row.show">
      <TableLine ref="LineRef"
                 :row="item"
                 v-for="item in row.child"
                 :key="item.chapter_id" />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  props: {
    row: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    doPaper (row) {
      // console.log(row.chapter_id)
      let params = {
        subject_id: row.subject_id || window.localStorage.getItem('chapter_subject_id'),
        chapter_id: row.chapter_id,
        is_weak: 0,
        type:10
      }
      this.$router.push('/doPaper?type=8&form=' + JSON.stringify(params))
    },
    cut (bool) {
      this.$set(this.row, 'show', bool)
      this.$forceUpdate()
      // this.$nextTick(()=>{
      //   document.getele
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
// &::-webkit-scrollbar {
//   width: 0 !important;
// }
.parent {
  height: 80rem;
  border-bottom: 1rem solid #f1f1f1;
  // &:hover {
  //   background: rgba($color: #2196f3, $alpha: 0.1);
  // }
}

div {
  height: 80rem;
  line-height: 80rem;
}
.subject {
  font-size: 20rem;
  font-weight: 500;
  color: #333333;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 50rem;
  text-indent: 20rem;
  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
      width: 28rem;
      height: 28rem;
      cursor: pointer;
    }
  }
}
.pl {
  font-size: 20rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 24rem;
    height: 23rem;
    margin-left: 10rem;
  }
}
.btn {
  width: 120rem;
  height: 44rem;
  border-radius: 5rem;
  font-size: 20rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  margin-right: 20rem;
  margin-top: 20rem;
  cursor: pointer;
  img {
    width: 32rem;
    height: 32rem;
    cursor: pointer;
    margin-right: 20rem;
  }
  &:nth-child(1) {
    color: #ffffff;
    background: #2196f3;
  }
}
.btn_img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20rem;
  img {
    width: 32rem;
    height: 32rem;
    padding: 14rem;
    cursor: pointer;
  }
}
</style>