import { instance } from '@/utils/http.js'

export const syncTime = function (data) {
  return instance({
    url: '/api/rpc/v1/user/usage',
    baseURL: process.env.NODE_ENV === 'development' ? 'http://10.10.11.250:705' : 'https://slbk.sunrayai.cn',
    method: 'options',
    data,

  })
}
export const applyToken = function (params) {
  return instance({
    url: '/api/rpc/v1/token',
    baseURL: process.env.NODE_ENV === 'development' ? 'http://10.10.11.250:705' : 'https://slbk.sunrayai.cn',
    method: 'get',
    params,
  })
}

export const syncTimePost = function (data) {
  return instance({
    url: '/api/rpc/v1/user/usage',
    baseURL: process.env.NODE_ENV === 'development' ? 'http://10.10.11.250:705' : 'https://slbk.sunrayai.cn',
    method: 'post',
    data,
    

  })
}